<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="9">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.coverage_type"
                      :rules="rules.text50Required"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      :label="'Requiere valoración'"
                      v-model="data.revision"
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { storeUpdate, show } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "proveedores.coberturas",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        coverage_type: "",
        revision: false,
      },
      rules: {},
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      //validate form data
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              //store or update action
              storeUpdate(
                "coverage/types",
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show("coverage/types", this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    }
    this.rules = rules();
  },
};
</script>